@import '~antd/es/style/themes/default.less';
body {
  font-family: '微软雅黑', '微软雅黑 Regular',  sans-serif;
  font-size: 14px;

  margin: 0;

  color: @text-color;
}
ol,
dl,
ul {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}

input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  color: #bbbdc4;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 适配火狐 */
  color: #bbbdc4;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ 适配火狐 */
  color: #bbbdc4;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+  适配ie*/
  color: #bbbdc4;
}

.ant-pagination {
  // width: 100%;

  // text-align: right;
  // .ant-pagination-total-text {
  //   float: left;
  // }
  .ant-pagination-item-active {
    border-radius: 50%;
    background-color: @primary-color;
    > a {
      color: #fff !important;
    }
  }
  .ant-pagination-options {
    .ant-select-selector {
      color: @link-color !important;
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}
.ant-input-number-handler-wrap {
  display: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: inset 0 0 0 1420px #fff;
}

.mock-vertical-track {
  width: 10px;

  transition: opacity .3s;

  background-color: transparent;
}

.mock-horizontal-track {
  height: 10px;

  transition: opacity .3s;

  background-color: transparent;
}

.mock-vertical-handler {
  right: 1px;

  width: 8px;

  transition: opacity .3s;

  border-radius: 4px;
  background-color: rgba(0, 0, 0, .3);
  &:hover {
    background-color: rgba(0, 0, 0, .5);
  }
}

.mock-horizontal-handler {
  bottom: 1px;

  height: 8px;

  transition: opacity .3s;

  border-radius: 4px;
  background-color: rgba(0, 0, 0, .3);
  &:hover {
    background-color: rgba(0, 0, 0, .5);
  }
}

#ems {
  .ant-timeline-item-head-blue {
    background-color: @primary-color;
  }
  .ant-timeline-item-head-gray {
    background-color: @grey-5;
  }
  .ant-timeline-item:first-child {
    .ant-timeline-item-tail {
      border-color: @primary-color;
    }
  }
}

.ant-table-cell {
  padding: 16px 12px !important;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;