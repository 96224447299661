@import '~antd/lib/style/themes/default.less';
.container {
  position: relative;

  padding-top: 64px;
  padding-bottom: 1px;
}
.error {
  color: @error-color;
}
.header {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 64px;
  padding: 0 220px;

  background-color: @primary-color;
}
.header-left {
  position: absolute;
  top: 0;
  left: 24px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  height: 100%;
}
.header-title {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  > img {
    width: 32px;
    height: 32px;
  }
  > span {
    font-size: 18px;

    padding-left: 16px;

    color: #fff;
  }
}
.header-right {
  position: absolute;
  top: 0;
  right: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  cursor: pointer;
  .header-person__name {
    font-size: @font-size-base;

    padding-right: 16px;

    color: #fff;
  }
}
.header-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  width: 720px;
  height: 100%;
  margin: 0 auto;
  a {
    font-size: @font-size-base;

    color: rgba(255, 255, 255, .55);
    &.active {
      font-weight: bold;

      color: #fff;
    }
  }
}
.body {
  position: relative;
  z-index: 0;
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;