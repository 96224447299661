@import '~antd/lib/style/themes/default.less';
.container {
  width: 720px;
  margin: 0 auto;
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}

.header {
  font-size: @heading-3-size;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;

  color: @heading-color;
}

.body {
  margin-bottom: 24px;
  padding: 24px 16px 16px 16px;

  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  background-color: #fff;
  box-shadow: rgb(183, 187, 200) 0 1px 3px 0;
}

.form {
  label {
    position: relative;

    width: 100%;
    padding-left: 40px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;

      display: flex !important;
      align-items: center;
      justify-content: center;

      width: 32px;
      height: 100%;
    }
  }
}

.long-input {
  width: 304px !important;
}

.short-input {
  width: 240px !important;
}

.btn-line {
  margin-right: 8px;

  text-align: right;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;