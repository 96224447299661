@import '~antd/lib/style/themes/default.less';
.container {
  position: relative;

  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  height: 48px;
}

.header-icon {
  overflow: hidden;

  width: 32px;
  height: 32px;
  margin-right: 24px;

  border-radius: 50%;
}

.header-title {
  font-size: @heading-3-size;
  font-weight: bold;

  color: @heading-color;
}

.title-line {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  height: 40px;
  padding: 0 24px;
}

.title-icon {
  width: 12px;
  height: 14px;
  margin-right: 16px;
}

.title-text {
  font-size: @font-size-base;
  font-weight: bold;

  color: @heading-color;
}

.block {
  font-size: @font-size-base;

  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;

  padding: 0 24px;
  + .title-line {
    margin-top: 16px;
  }
}

.block-number {
  font-size: @font-size-base;
  line-height: 20px;

  margin-right: 16px;
  padding: 6px 0;
}

.list {
  flex: 1;
}

.li {
  line-height: 24px;

  padding: 4px;
}

.li-title {
  font-size: @font-size-base;

  margin-right: 4px;

  color: @heading-color;
  &::after {
    content: '：';
  }
}

.li-text {
  flex: 1;
}

.reason {
  margin-top: 8px;
}

.full-block {
  padding: 0 24px;
}

.pic-block {
  overflow: hidden;

  padding: 12px 12px;

  text-align: center;
}

.pic-list {
  display: inline-block;

  width: 672px;

  text-align: left;
}

.pic-cell {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 96px;
  height: 96px;
  margin: 8px;

  border: 1px dashed @border-color-base;
  border-radius: @border-radius-base;
}

.evidence-pic {
  width: 80px;
  height: 80px;
}

.bottom-btn-line {
  margin-bottom: 24px;

  text-align: right;
}

.normal-btn {
  color: @primary-color;
}

.identity-block {
  position: absolute;
  top: 0;
  right: -220px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  width: 232px;
}

.identity-cell {
  display: inline-block;

  width: 108px;

  cursor: pointer;
  user-select: none;
  text-align: center;

  border: 1px dashed @border-color-base;
  border-radius: @border-radius-base;
}

.identity-pic {
  width: 96px;
  margin: 4px 0;
}

.identity-title {
  font-size: @font-size-base;
  line-height: 24px;

  margin-bottom: 4px;

  color: @text-color-secondary;
}

.claim-li {
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  justify-content: space-between;

  padding: 4px 0;
}
.claim-title {
  display: flex;
  align-self: flex-start;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;

  padding-right: 24px;
}
.claim-desc {
  color: @text-color;
}
.order {
  font-size: 12px;
  line-height: 16px;

  display: inline-block;
  flex-shrink: 0;

  width: 16px;
  height: 16px;
  margin: 4px 12px 4px 0;

  text-align: center;

  color: #fff;
  border-radius: 50%;
  background-color: @grey-8;
}
.evidence-title {
  padding-left: 22px;
  color: #333;
}
.evidence-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 22px;
  +.evidence-item {
    margin-top: 12px;
  }
}
.evidence-item-content {
  flex: 1;
  padding-right: 24px;
}
.evidence-item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.evidence-time {
  color: #999;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;