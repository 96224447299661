@import '~antd/es/style/themes/default.less';
.container {
  word-break: break-all;
  &.mobile {
    .header-nav, .body, .info-content, .icon-line {
      width: 100%;
    }
    .info-content {
      padding: 12px;
    }
    .header {
      padding: 12px;
      .header-title-img, .header-to-guide  {
        line-height: 48px;
        height: 48px;
      }
    }
    .canvas-title {
      font-size: 40px;
    }
  }
}
.container::after {
  display: block;
  clear: both;

  content: '';
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 84px;
  padding: 12px 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}
.header-nav {
  position: relative;
  z-index: 1;

  box-sizing: border-box;
  width: 1280px;
  margin: 0 auto;
}
.header-to-guide {
  font-size: 16px;
  font-weight: bold;
  line-height: 60px;

  float: right;

  text-decoration: none;

  color: #0039bf;
}
.header-title-img {
  height: 60px;
}
.canvas {
  height: 423px;
  background: url(../assets/img/public-bg.png) no-repeat center;
}
.canvas-title {
  font-size: 50px;
  line-height: 170px;
  padding-top: 170px;
  text-align: center;
  color: #333;
}
.footer {
  width: 100%;
  font-size: 14px;
  line-height: 80px;

  height: 80px;

  text-align: center;

  color: #fff;
  background-color: #0d0d0d;
}
.footer a {
  text-decoration: none;

  color: #fff;
}
.body {
  padding: 0 24px;
  width: 1280px;
  margin: 0 auto;
}
.title-line {
  line-height: 60px;
  font-size: 18px;
  font-weight: bold;
  color: @heading-color;
}
.info-content {
  width: 1232px;
  padding: 24px 48px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 8px;
  margin: -100px auto 24px auto;
  &.inner {
    margin: 0 auto;
    border: 0;
  }
}
.name-line {
  font-size: 20px;
  color: #333;
  line-height: 32px;
}
.other-info-line {
  line-height: 28px;
  color: #333;
  margin-bottom: 8px;
  span {
    margin-right: 24px;
  }
}
.icon-line {
  width: 500px;
  line-height: 24px;
}
.icon-cell {
  display: inline-block;
  width: 250px;
  padding: 4px 0;
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
    margin-right: 12px;
  }
  span {
    vertical-align: middle;
    font-size: 12px;
  }
}
.block-title {
  position: relative;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  color: #0039BF;
  padding-left: 12px;
  margin-top: 10px;
}
.block-title::before {
  content: '';
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 0;
  width: 4px;
  background-color: #0039BF;
  border-radius: 4px;
}
.block-content {
  font-size: 14px;
  color: #555;
  padding: 4px 0;
  line-height: 24px;
  margin-bottom: 24px;
}
.tree-list {
  position: relative;
  font-size: 14px;
  padding-left: 24px;
  color: #555
}
.tree-list::before {
  content: '';
  position: absolute;
  top: 14px;
  bottom: 14px;
  left: 8px;
  border-right: 1px dashed #aaa;
}
.leaf {
  position: relative;
}
.leaf-text {
  position: relative;
  height: 28px;
  line-height: 28px;
}
.leaf-text::before {
  content: '';
  position: absolute;
  left: -14px;
  width: 10px;
  top: 14px;
  border-top: 1px dashed #aaa;
}
.leaf:last-child::after {
  content: '';
  position: absolute;
  left: -20px;
  top: 15px;
  bottom: 0;
  width: 10px;
  background-color: #fff;
}
.leaf > .tree-list::after {
  content: '';
  position: absolute;
  top: 0;
  height: 12px;
  left: 8px;
  border-left: 1px dashed #aaa;
}
.qualifaction {
  padding: 4px 0;
}
.qualifaction-line {
  line-height: 32px;
  font-size: 14px;
  color: #555;
}
.qualifaction-line span {
  margin-right: 20px;
}
.qualifaction-line span:nth-child(2) {
  color: #999;
}
.btn-line {
  margin-bottom: 12px;
}
@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;