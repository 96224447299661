@import '~antd/lib/style/themes/default.less';
.container {
  width: 720px;
  margin: 0 auto;
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}

.header-block {
  display: flex;
  align-items: center;
  flex-direction: row;

  height: 84px;

  justify-items: flex-start;
}

.header-title {
  font-size: @heading-4-size;

  color: @heading-color;
}

.header-sub-title {
  font-size: @font-size-base;

  margin-top: 4px;

  color: @text-color-secondary;
  &::before {
    display: inline-block;

    padding: 0 8px;

    content: '/';
  }
}
.list {
  margin-bottom: -24px;
}
.card {
  margin-bottom: 24px;

  cursor: pointer;

  border: 1px solid @border-color-split;
  border-radius: @border-radius-base;
  &.next {
    .card-header-time {
      background-color: @vice-6;
    }
  }
}

.card-header {
  display: flex;
  align-items: center;
  flex-direction: row;

  height: 48px;

  border-bottom: 1px solid @border-color-split;

  justify-items: flex-start;
}

.card-header-time {
  font-size: @font-size-lg;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 100%;

  color: #fff;
  border-top-left-radius: @border-radius-base;
  background-color: @primary-color;
}

.card-header-title {
  font-size: @font-size-lg;

  display: flex;
  flex: 1;
  flex-direction: row;

  padding: 16px;

  color: @heading-color;
  > span:nth-child(1) {
    margin-right: 8px;
  }
}

.card-body {
  display: flex;
}
.card-body-time {
  line-height: 24px;

  width: 48px;
  min-height: 80px;
  padding: 12px 0;

  text-align: center;
  word-break: break-all;

  background-color: @grey-3;
}
.card-body-content {
  flex: 1;

  padding: 12px 16px;
}
.card-line {
  line-height: 24px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  color: @text-color-secondary;
  > span {
    display: inline-block;
    &:nth-child(1) {
      width: 72px;
    }
    &:nth-child(2) {
      flex: 1;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100px;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;