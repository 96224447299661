@import '~antd/es/style/themes/default.less';
.container {
  &.mobile {
    .search-block-inner {
      border: 0;
    }
    .body {
      padding: 0 12px;
    }
    .header-nav, .body, .search-block-inner {
      width: 100% !important;
      box-sizing: border-box;
    }
    .header {
      padding: 12px;
      .header-title-img, .header-to-guide  {
        line-height: 48px;
        height: 48px;
      }
    }
    .public-notice-block {
      padding: 0;
    }
    .public-notice {
      width: 100%;
      border: 0;
      padding: 12px;
      margin-top: 0;
      margin-bottom: -24px;
    }
  }
}
.form {
  overflow: hidden;
}
.container::after {
  display: block;
  clear: both;

  content: ''; 
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 84px;
  padding: 12px 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}
.header-nav {
  position: relative;
  z-index: 1;

  box-sizing: border-box;
  width: 1280px;
  margin: 0 auto;
}
.header-to-guide {
  font-size: 16px;
  font-weight: bold;
  line-height: 60px;

  float: right;

  text-decoration: none;

  color: #0039bf;
}
.header-title-img {
  height: 60px;
}
.canvas {
  height: 423px;
  background: url(../assets/img/public-bg.png) no-repeat center;
}
.canvas-title {
  font-size: 50px;
  line-height: 170px;
  padding-top: 170px;
  text-align: center;
  color: #333;
}
.footer {
  width: 100%;
  font-size: 14px;
  line-height: 80px;

  height: 80px;

  text-align: center;

  color: #fff;
  background-color: #0d0d0d;
}
.footer a {
  text-decoration: none;

  color: #fff;
}
.body {
  padding: 0 24px;
  width: 1280px;
  margin: 0 auto;
}
.title-line {
  line-height: 60px;
  font-size: 18px;
  font-weight: bold;
  color: @heading-color;
}
.canvas-search {
  width: 650px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
}
.canvas-label {
  width: 80px;
  text-align: right;
  font-size: 16px;
  flex-shrink: 0;
  color: #333;
}
.canvas-input {
  flex: 1;
  border: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  font-size: 16px;
  color: #333;
}
.canvas-btn {
  width: 60px;
  height: 50px;
  border: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #666;
  cursor: pointer;
  font-size: 16px;
  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.public-notice-block {
  &.inner {
    padding: 12px 0;
    box-sizing: content-box;
  }
}
.public-notice {
  width: 1232px;
  background-color: #fefaf0;
  margin: 0 auto;
  border: 1px solid #efefef;
  padding: 12px 24px;
  margin-top: -24px;
  border-radius: 4px;
  color: #bd837b;
  &.inner {
    margin: 0 auto !important;
  }
}
.public-notice-date {
  text-align: right;
}
.search-block {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
  &.inner {
    margin-top: 0;
    padding-top: 12px;
  }
}
.search-block-inner {
  display: inline-block;
  text-align: left;
  width: 1232px;
  padding: 24px 24px 0 24px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 8px;
}
.bts {
  text-align: right;
  width: 100%;
}
.info-content {
  width: 1232px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 8px;
  margin: -100px auto 24px auto;
}
.pagiantion {
  text-align: right;
}
.list {
  padding-bottom: 24px;
}
.item {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
}
.line {
  word-break: break-all;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  padding: 4px 0;
}
.label {
  color: #999;
  flex-shrink: 0;
  text-align: right;
  &::after {
    content: '：'
  }
}
.value {
  flex: 1;
}
.detail-btn {
  text-align: right;
}
.versions {
  margin-bottom: 12px;
  text-align: right;
}
.version {
  position: relative;
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  user-select: none;
  margin-right: 4px;
  &:hover {
    background-color: lighten(#bd837b, 30);
  }
  &.active {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 2px solid #bd837b;
    }
  }
}
.loading {
  width: 1200px;
  margin: 0 auto;
  &.inner {
    width: 100%;
  }
}
@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;